<template>
  <div
    class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-16 shadow-lg items-center"
  >
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-5">
        <div class="flex">
         <button
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mr-3"
            @click="RouterBack()"
          >
            <i class="material-icons">chevron_left</i>
          </button>
          <div v-if="!formData._id" class="text-2xl font-bold text-green-500 ">
            {{$t('new_user')}}
          </div>
          <div v-else class="text-2xl font-bold text-green-500 ">{{$t('edit_user')}}</div>
        </div>
      </div>

      <div class="relative">
         <loading :active.sync="loading" :is-full-page="fullPage"></loading>
        <div class="bg-white rounded-lg p-6">
          <div class="grid lg:grid-cols-2 gap-6">
            <div
             
              class="border focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="fullName" class="bg-white text-gray-700 px-1"
                    >{{$t('full_name')}} *</label
                  >
                </p>
              </div>
              <p>
                <input
                  id="fullName"
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.fullName"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </p>
            </div>
            <div
              
              class="border focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="phone" class="bg-white text-gray-700 px-1"
                    >{{$t('phone')}} *</label
                  >
                </p>
              </div>
              <p>
               

                <input
                  id="phone"
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.phone"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </p>
            </div>
            <div
              class="border focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="username" class="bg-white text-gray-700 px-1"
                    >{{$t('username')}} *</label
                  >
                </p>
              </div>
              <p>
                <input
                  id="username"
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.username"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                  @keydown.space="(event) => event.preventDefault()"
                />
              </p>
            </div>

            <div
              class="border focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="email" class="bg-white text-gray-700 px-1"
                    >{{$t('email')}} *</label
                  >
                </p>
              </div>
              <p>
                <input
                  autocomplete="false"
                  tabindex="0"
                  type="email"
                  v-model="formData.email"
                  class="py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full"
                />
              </p>
            </div>

            <div
              v-if="formData._id"
              class="border focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="company" class="bg-white text-gray-700 px-1"
                    >{{$t('store')}} *</label
                  >
                </p>
              </div>
              <p>
                <input
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.company"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </p>
            </div>

            <div
              class="border h-10 focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="name" class="bg-white text-gray-700 px-1"
                    >{{$t('role')}} *</label
                  >
                </p>
              </div>
              <p>
                <v-select  label="name" @input="onChangeRole(formData.role)" @search="searchRoles($event,'hh')" v-model="formData.role" :options="roles"></v-select>
                <!-- <select
                  autocomplete="type"
                  v-model="formData.role"
                  @change="onChangeRole(formData.role)"
                  class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
                >
                  <option selected disabled :value="null">Role</option>
                  <option v-for="role in roles" :key="role._id" :value="role">
                    {{ role.name }}
                  </option>
                </select> -->
              </p>
            </div>

            <div
              v-if="livreur && selectedRole == livreur"
              class="border hover:border-green-500  mt-4 px-4 w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="description" class="bg-white text-gray-700 px-1"
                    >{{$t('select_zones')}}
                    <span :class="$colors.required">*</span></label
                  >
                </p>
              </div>
              <div class="">
                <div class="w-full mt-2">
                  <table class="table w-full text-center">
                    <thead class="bg-gray-100 text-black">
                      <tr>
                        <th
                          class="px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider"
                        >
                          {{$t('zones')}}
                        </th>

                        <th
                          class="px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider"
                        ></th>
                        <th
                          class="px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider"
                        >
                          {{$t('add')}}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in zones"
                        :key="index"
                        :class="index % 2 == 0 ? 'bg-gray-100' : ''"
                      >
                        <td
                          class="px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"
                        >
                          <p class="text-gray-700 capitalize">
                            {{ item.name }}
                          </p>
                        </td>

                        <td
                          class="px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"
                        ></td>
                        <td
                          class="px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"
                        >
                          <p
                            class="text-gray-600 ml-3 text-semibold capitalize"
                          >
                            <label
                              class="inline-flex text-sm items-center capitalize"
                            >
                              <input
                                @change="addZones(item)" @click="getRowDetail(item._id)"
                                v-model="item.check" 
                                type="checkbox" :id="item._id"
                                class="form-checkbox rowCheckbox border-gray-500 h-4 w-4 text-green-500 "
                              />
                            </label>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              v-if="formData._id && formData.type=='Seller'"
              class="border focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="rib" class="bg-white text-gray-700 px-1"
                    >{{$t('rib')}} *</label
                  >
                </p>
              </div>
              <p>
                <input
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.rib"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </p>
            </div>
            <div
              v-if="formData._id && formData.type === 'Seller' && currentUser.type !== 'Seller'"
              class="border focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="payment" class="bg-white text-gray-700 px-1"
                    >{{$t('Paiement par')}} *</label
                  >
                </p>
              </div>
              <p>
                <input
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.paymentBy"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </p>
            </div>

            <div
              v-if="formData.type && formData.type === 'Seller' && currentUser.type !== 'Seller'"
              class="border focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="payment" class="bg-white text-gray-700 px-1"
                    >{{$t('Account Type')}} *</label
                  >
                </p>
              </div>
              <p>
                <v-select v-model="formData.accountType" :options="['seller','affiliate']" multiple></v-select>
              </p>
            </div>

            <!-- coutries -->
            <div class="w-full">
              <div class="flex justify-center items-center">
                <svg  xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" class="fill-current mr-2"><path  d="M12 6.453l9 8.375v9.172h-6v-6h-6v6h-6v-9.172l9-8.375zm12 5.695l-12-11.148-12 11.133 1.361 1.465 10.639-9.868 10.639 9.883 1.361-1.465z"></path></svg>
                <div v-if="$f.getAccess(currentUser.role.permissions,'users','create')" class="border w-full seller-select h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="Countries" class="bg-white text-gray-700 px-1"> {{$t('warehouses')}} <span :class="$colors.required">*</span></label>
                          </p>
                        </div>    
                        <p>
                          <v-select  label="countryName" @input="setSelectedCountries" v-model="formData.countries" :options="warehouses" multiple>
                                  <template slot="option" slot-scope="option">
                                      <img class="flag-img" :src="$f.getCountryCode(option.country)"/>
                                      {{ option.countryName }} 
                                  </template>
                            </v-select>
                        </p>         
                  </div>  
              </div>  
              <span v-if="checkWarehousingParent.length>0 && formData.role && ['StockManager','Administrateur'].indexOf(formData.role.type||this.formData.type) != -1" class="ware_prin">
                    <div  class="border mll-4 hover:border-blue-400  w-10/12 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="zonessous" class="bg-white text-gray-700 px-1">Zone (Ville) <span :class="$colors.required">*</span></label>
                          </p>
                        </div>
                        <p>
                          <v-select label="name" @input="setSelectedsous_warehouses" v-model="formData.sous_warehouses"  :options="checkWarehousingParent" multiple ></v-select>
                        </p>
                  </div>
                </span >
            </div>  


            <!-- /coutries -->
            <div v-if="!formData._id" class="flex items-center justify-between">
            <span class="w-1/5 border-b dark:border-gray-600 md:w-1/4"></span> <span @click="CreateNewRole" class="text-xs text-green-500 font-bold cursor-pointer  uppercase dark:text-green-400 hover:underline">{{$t('create_new_role')}}</span> <span class="w-1/5 border-b dark:border-gray-600 md:w-1/4"></span>
          </div>
          </div>
          <!--fees seller -->
          <div v-if="formData._id && formData.type=='Seller'">
            <div class="flex mt-10 mb-5 items-center text-center">
                <hr class="border-gray-300 border-1 w-full rounded-md">
                <label @click="Showfees" class="block font-medium font-medium text-base cursor-pointer text-green-500  w-full"><span class="font-medium text-base text-green-800 dark:text-green-200">{{$t('fees_seller')}}</span> <span class="block"><i class="material-icons" style="transition: 0.3s" :style="showMoreInfo? 'transform: rotate(180deg)' : ''">keyboard_arrow_down</i></span></label>
                <hr class="border-gray-300 border-1 w-full rounded-md">
            </div>

            <div  v-if="showMoreInfo" class="flex flex-wrap">
              <div class="w-full lg:w-1/5 px-4 mb-4">
                <div class="border focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                    <p>
                        <label class="bg-white text-gray-700 px-1" for="grid-password">{{$t('confirmation_fees')}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></label>
                      </p>  
                  </div>
                    <p>
                      <input type="number" v-model="fees.confirmation" min="0" class="py-2 text-xs px-2 outline-none block h-full w-full">
                  </p>   
                </div>
              </div>
               <div class="w-full lg:w-1/5 px-4 mb-4">
                <div class="border focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                    <p>
                        <label class="bg-white text-gray-700 px-1" for="fulfillement_fees">{{$t('fulfillement_fees')}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></label>
                      </p>  
                  </div>
                    <p>
                      <input type="number" v-model="fees.fulfillement_fees" min="0" class="py-2 text-xs px-2 outline-none block h-full w-full">
                  </p>   
                </div>
              </div>
              <div class="w-full lg:w-1/5 px-4 mb-4">
                <div class="border focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                      <p>
                        <label class="bg-white text-gray-700 px-1" for="grid-password">{{$t('shipping_fees')}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></label>
                      </p>  
                  </div>
                    <p>
                      <input type="number" v-model="fees.shipping" min="0" class="py-2 text-xs px-2 outline-none block h-full w-full">
                    </p>  
                </div>
              </div>
              <div class="w-full lg:w-1/5 px-4 mb-4">
                <div class="border focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                    <p>
                        <label class="bg-white text-gray-700 px-1" for="grid-password">{{$t('return_fees')}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></label>
                    </p>  
                  </div>
                    <p>
                      <input type="number" v-model="fees.refund" min="0" class="py-2 text-xs px-2 outline-none block h-full w-full">
                    </p>       
                </div>
              </div>
              <div class="w-full lg:w-1/5 px-4 mb-4">
                <div class="border focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                    <p>
                        <label class="bg-white text-gray-700 px-1" for="grid-password">{{$t('cancelation_fees')}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></label>
                    </p>   
                  </div>
                    <p>
                      <input type="number" v-model="fees.cancelation" min="0" class="py-2 text-xs px-2 outline-none block h-full w-full">
                    </p>  
                </div>
              </div>
               <div class="w-full lg:w-1/5 px-4 mb-4">
                <div class="border focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                    <p>
                        <label class="bg-white text-gray-700 px-1" for="grid-password">{{$t('quality_control')}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></label>
                    </p>   
                  </div>
                    <p>
                      <input type="number" v-model="fees.quality_control" min="0" class="py-2 text-xs px-2 outline-none block h-full w-full">
                    </p>  
                </div>
              </div>
            </div>

            <!--services -->
             <div class="flex mt-10 mb-5 items-center text-center">
                <hr class="border-gray-300 border-1 w-full rounded-md">
                <label class="block font-medium text-sm cursor-pointer text-green-500  w-full"><span class="font-medium text-base text-green-800 dark:text-green-200">{{$t('services')}}</span> </label>
                <hr class="border-gray-300 border-1 w-full rounded-md">
            </div>
              
            <div class="flex flex-col lg:flex-row">
              <div id="confirmation" @click="activate('confirmation')" :class="{active: settings.confirmation}" class="intro-y flex-1 box-1 py-16 cursor-pointer zoom-in">
              <div class="check"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
              <div class="text-xl font-medium text-center mt-10">Confirmation</div>
              <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
              <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
            </div>
            <div id="fulfillement" @click="activate('fulfillement')" :class="{active: settings.fulfillement}" class="intro-y flex-1 box-1 py-16 lg:ml-5 mb-5 lg:mb-0 cursor-pointer zoom-in">
                <div class="check"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
                 <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                <div class="text-xl font-medium text-center mt-10">Warehouse</div>
                <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
            </div>
            <div id="shipping" @click="activate('shipping')" :class="{active: settings.shipping}" class="intro-y flex-1 box-1 py-16 lg:ml-5 cursor-pointer zoom-in">
                <div class="check"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                <div class="text-xl font-medium text-center mt-10">Shipping</div>
                <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
            </div>
         </div>

          </div>
          <!--/ fees seller -->
          <div class="mt-20 pt-3 pb-4 flex justify-center">
            <button
              class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600  hover:shadow-inner hover:bg-green-700  transition-all duration-300"
              @click="save"
            >
              {{$t('save')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import configStatus from '../callcenter/configStatus';
export default {
  props: {
     idWharhouse: { type: Object},
     currentUser: { type: Object},
   },
  data() {
    return {
      formData: {
        category: null,
        accountType:[],
        role: null,
        zones: [],
        countries:[],
        fees:{},
        settings:[],
        sous_warehouses:[],
        type: "",
      },
      roles: [],
      zones: [],
      selectContries:[],
      currentZone:[],
      allZones:[],
      types: [
        "Seller",
        "TeleConsultant",
         "NRP",
        "StockManager",
        "Courier",
        "Accountant",
        "Return",
        "Admin",
        "CancelledOrder",
        'ToRemind'
      ],
      categories: [],
      warehouses:[],
      options:this.$countries,
      livreur: "",
      selectedRole: "",
      roleformdata:"",
      tempZones: [],
      checkbox:true,
      showMoreInfo:false,
      loading:false,
      fullPage: false,
      currency:"",
      //idWharhouse:null,
      fees:{
        confirmation: -1,
        fulfillement_fees:-1,
        shipping: -1,
        refund: -1,
        change: -1,
        quality_control: -1,
      },
      settings:{
         open_colis:false,
         confirmation:false,
         fulfillement:false,
         shipping:false,
      },
      checkWarehousingParent:[]
    };
  },

  async mounted() {
   // await this.getUser();
    await this.getCategories();
    await this.getRoles();
    await this.getZones();
    await this.dataToEdit();
    await this.getRoleByType();
    await this.getWarehouses();
    this.getDtaStorage();
    //this.DeleteDtaStorage();
     console.log('chck functi',this.$f.getCountryCodeByName(this.options,'hassa2'));
     if (this.formData._id) {
       this.formData.role=this.roleformdata;
     }
  },
  computed: {
    warhouseSelected(){
      return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.currentUser,['countries'])})
    },
  },
   watch: {
      'warhouseSelected': async function(oldVal, newVal) {
          // await this.getwharhouseId()
          // await this.dataToEdit();
          // await this.getZones();
      },
      'idWharhouse': async function(oldVal, newVal) {
            this.idWharhouse=oldVal;  
            await this.dataToEdit();
            await this.getZones();
        }
  },
  methods: {
     RouterBack: function() {
       this.$router.back();
    },
   
    validateEmail(email) {
      // Regular expression pattern for valid email address
       const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
       // Check if email matches the pattern
       return pattern.test(email);
    },
    activate:function(el){
        this.settings[el] = !this.settings[el];
    },
    async getUser() {
      const res = await this.$server.me("users");
      //console.log(res);
      if (res.content) this.currentUser = res.content;
      else this.currentUser = {};
      console.log('currentUser__fff____________',this.currentUser)
    },
    async getwharhouseId(){
      await this.$store.dispatch('wharhouse/getwharhouseId',{country:await this.warhouseSelected}).then((res) => {
            this.idWharhouse=res;
        })
    },
     async getCurrency(){
      await this.$store.dispatch('wharhouse/getCurrency').then((res) => {
            this.currency=res;
        })
    },
    async changeData(data,warhouse, element) {
        for (var i in data) {
          if (data[i].warehouse == warhouse.toString()) {
             data[i] = element;
             break; //Stop this loop, we found it!
          }
        }
     },
     setSelectedsous_warehouses(sousW){
      console.log('sousW ------------',sousW)
      console.log('sous_warehouses ------------',this.formData.sous_warehouses)
     },
     async setSelectedCountries(countries){
      if(countries){
          this.formData.countries.forEach(function(part, index, theArray) {
           if(typeof part === 'object' && part !== null)theArray[index] = part.countryName;
          }.bind(this));    
          let uniqueChars = this.formData.countries.filter((c, index) => {return this.formData.countries.indexOf(c) === index;});
          this.formData.countries=uniqueChars;
          console.log('uniqueChars 11------------',uniqueChars)
          this.checkWarehousingParent=this.formData.sous_warehouses=[];
          if (countries && countries.length>0 && this.formData.role && ['StockManager','Administrateur'].indexOf(this.formData.role.type) != -1) {
            console.log('country 11------------',countries[0])
            await this.getWarehousingParent(countries[0]); 
          }
      }
       //console.log('formdta___',this.formData.countries)
    }, 
    async getWarehousingParent(country) {
          console.log('country getWarehousingParent-----',country)
          console.log('filter getWarehousingParent-----',{ country:country.length==2?country:this.$f.getCountryCodeByName(this.options,country),all:true,limit:10})
          const resW = await this.$server.search("warehouses", { country:country.length==2?country:this.$f.getCountryCodeByName(this.options,country),all:true,limit:10}); 
          console.log('resW-----',resW.content)
          if(resW.content && resW.content.results) {
              this.checkWarehousingParent=resW.content.results.filter(item => !item.is_primary).sort((a,b) => (a._id > b._id) ? 1 : ((b._id > a._id) ? -1 : 0));
          }else this.checkWarehousingParent=[];
    },
    async getWarehouses(){
        const filter = {limit:10};
        const res = await this.$server.search("warehouses",filter);
        if (res.content.results) {
            this.warehouses = res.content.results;
            // for(let i in data){
            //   this.warehouses.push({name:this.$f.getCountryNameByCode(this.options,data[i].country),code:data[i].country})
            // }
        } else this.warehouses = [];
      }, 
    getDtaStorage(){
      //console.log('role___id',JSON.parse(localStorage.getItem("Role")));
     if (localStorage.getItem("Role") != null && localStorage.getItem("User_email") != null) {
       console.log('ooooooooooooooooooooooooook storage')
          let Role=JSON.parse(localStorage.getItem("Role"));
          this.formData.email=localStorage.getItem("User_email");
          this.selectedRole=Role;
          this.formData.role=Role;
          this.formData.type=Role.type;
         if(localStorage.getItem("User_username") != null) this.formData.username=localStorage.getItem("User_username");
         if(localStorage.getItem("User_fullName") != null) this.formData.fullName=localStorage.getItem("User_fullName");
     }
    },
    DeleteDtaStorage(){
       localStorage.removeItem('Role');
       localStorage.removeItem('User_email');
       localStorage.removeItem('User_username');
       localStorage.removeItem('User_fullName');
    },
    searchRoles: _.debounce(async function(search) {
      const filter = {
          limit:this.limit,
          name: search,
        };
        const res= await this.$server.find("roles",filter);

      if (res.content.results) this.roles = res.content.results;
      else this.roles = [];
    }, 500),
    CreateNewRole(){
       localStorage.setItem('User_email', this.formData.email);
       localStorage.setItem('User_username', this.formData.username);
       localStorage.setItem('User_fullName', this.formData.fullName);
       this.$router.push('/roles/new');
    },
    Showfees() {
      this.showMoreInfo =  !this.showMoreInfo ;
    },
    async save() {
      if (this.formData._id) {
         this.ConvertArrayToCountyCode(this.formData.countries);
         this.formData.email=this.formData.email.toLowerCase().trim();

        if(['StockManager','Administrateur'].indexOf(this.formData.type) != -1){
            if(this.formData.countries.length>1)  {alert(`Le rôle ${this.formData.type} peu contient un seul warehouse !`, "warning");return false;}
           // if(this.checkWarehousingParent.length>0 && this.formData.sous_warehouses && this.formData.sous_warehouses.length==0) {alert(`Sous warehouse (Zones) required !`, "warning");return false;}  
           // if(this.formData.sous_warehouses.length>1)  {alert(`Le rôle ${this.formData.type} peu contient un seul sous warehouse !`, "warning");return false;}
        }else {this.formData.sous_warehouses=this.checkWarehousingParent=[];}

       if(this.formData.type == "Seller"){
             if(this.formData.accountType.length ===0 )  {alert(`Type account required !`, "warning");return false;}

            const dataWahrhouse = await this.$server.get("users",{ id: this.$route.params.id,warehouse:this.idWharhouse._id});
            const dataWahrhouseSetting = await this.$server.get("users",{ id: this.$route.params.id,warehouseSettings:this.idWharhouse._id});
            if(dataWahrhouse.content.length>0){
              this.changeData(this.formData.fees,this.idWharhouse._id,this.fees)
            }else{
              this.fees.warehouse=await this.idWharhouse._id;
              this.formData.fees.push(this.fees);
            }
            if(dataWahrhouseSetting.content.length>0){
               this.changeData(this.formData.settings,this.idWharhouse._id,this.settings)
            }else{
              this.settings.warehouse=await this.idWharhouse._id;
              this.formData.settings.push(this.settings);
            }
       }
        if(this.formData.countries.length==0){
          alert('Countries required', "warning");
          return false;
        }
        if(!this.validateEmail(this.formData.email)){
            alert('Please enter a valid email address', "warning");
            this.loading=false;
            return false;
          }
        const data = await this.$server.update("users", this.formData);

        if (data && data._id)  alert(this.$t('user_updated'), "success");
        else  alert(data, "warning");
      } else {

        if( this.formData.role==null && this.formData.type==''){
          alert(this.$t('role_required'), "warning");
        }else{
          if (localStorage.getItem("Role_id") === null && localStorage.getItem("User_email") === null) {
              this.formData.type = this.formData.role.type;
          }
        if (this.checkData()) {
            if (this.formData.username.length < 3 ) {
               alert(this.$t('username_not_valid'), "warning");
               return false;
           } 
           if(this.formData.type == "Seller"){
                if(this.formData.accountType.length ===0 ) {alert(`Type account required !`, "warning");return false;}
           }else this.formData.accountType=[]

         if(['StockManager','Administrateur'].indexOf(this.formData.type) != -1){
            if(this.formData.countries.length>1)  {alert(`Le rôle ${this.formData.type} peu contient un seul warehouse !`, "warning");return false;}
            if(this.checkWarehousingParent.length>0 && this.formData.sous_warehouses && this.formData.sous_warehouses.length==0) {alert(`Sous warehouse (Zones) required !`, "warning");return false;}  
           // if(this.formData.sous_warehouses.length>1)  {alert(`Le rôle ${this.formData.type} peu contient un seul sous warehouse !`, "warning");return false;}
        }else {this.formData.sous_warehouses=this.checkWarehousingParent=[];}

         if(!this.validateEmail(this.formData.email)){
            alert('Please enter a valid email address', "warning");
            this.loading=false;
            return false;
          }
          this.formData.email=this.formData.email.toLowerCase().trim();
           const checkUser = await this.$server.find("users",{name:this.formData.email});
          //  console.log('exist user_____',checkUser.content.results)
           if(checkUser.content.results.length>0){ 
               alert(this.$t('email_exist'), "warning");
               return false;
           }
          this.ConvertArrayToCountyCode(this.formData.countries);
          delete this.formData.fees;
          delete this.formData.settings;
          const data = await this.$server.create("users", this.formData);
          if (data && data._id) {
              alert(this.$t('user_created'), "success");
              this.DeleteDtaStorage();
              this.selectContries=[];
              this.formData = {
                zones: [],
                sous_warehouses:[],
                accountType:[]
              };
              this.checkWarehousingParent=[];
              for (let i = 0; i <this.zones.length; i++) {
                  this.zones[i].check='';
              } 
          } else  alert(data, "warning");
        } else  alert(this.$t('all_required_field'), "warning");
      }
      }
    },
    getRowDetail(zone_id){
       let itemInRow = this.formData.zones.filter(item => item === zone_id);
            let isItemInRow = itemInRow.length > 0;
                
        if (isItemInRow) { 
            let pos = this.formData.zones.map(function (e) {
                return e;
            }).indexOf(zone_id);
            this.formData.zones.splice(pos, 1);
        } else {
            this.formData.zones.push(zone_id);
        }
    },
    checkData() {
      if (this.formData.countries.length>0 && this.formData.role && this.formData.email && this.formData.type && this.formData.username && this.formData.fullName && this.formData.phone)
        return true;
      else return false;
    },
    async getRoleByType() {
      const filter = {
        type: "Courier",
      };
      const res = await this.$server.get("roles", filter);
      this.livreur = res.content._id;
    },
    async onChangeRole(data) {
     // if(data && !this.formData._id){
         
    //  }  
    if(data){
         console.log('is sous_warehouses 11',this.formData.sous_warehouses)
        this.formData.type=data.type;
        this.selectedRole = data._id;
        if(['StockManager','Administrateur'].indexOf(data.type) != -1 && this.formData.countries && this.formData.countries.length==1) {
          console.log('is detected',this.formData.countries[0])
          await this.getWarehousingParent(this.formData.countries[0]); 
          console.log('is sous_warehouses 22',this.formData.sous_warehouses)
        }else {this.checkWarehousingParent=this.formData.sous_warehouses=[];}
     }else {this.checkWarehousingParent=this.formData.sous_warehouses=[];}
      
    },
    addZones(item) {
      if (item.check) {
        const zone = {
          _id: item._id,
        };
        this.tempZones.push(zone);
      } else {
        let pos = this.tempZones
          .map(function (e) {
            return e._id;
          })
          .indexOf(item._id);
        this.tempZones.splice(pos, 1);
      }
    },
    /******************************************************************************************/
    async getElementInArray(data,value){
      console.log('data________________',data)
       console.log('value________________',value)
        var result  = data.filter(function(o){
            let whar=o.warehouse;
            if(o.warehouse._id) whar=o.warehouse._id;
            return whar == value.toString();
        } );
        return result? result[0] : null; // or undefined
    },
    async dataToEdit() {
      if (this.$route.params.id) {
        this.loading=true;
        const editData = await this.$server.get("users", {
          id: this.$route.params.id,
        });
        if(editData) this.loading=false;
        const dataWahrhouse = await this.$server.get("users",{ id: this.$route.params.id,warehouse:this.idWharhouse._id});
         const dataWahrhouseSetting = await this.$server.get("users",{id: this.$route.params.id,warehouseSettings:this.idWharhouse._id});
        if(dataWahrhouse.content.length>0){
           this.fees=await this.getElementInArray(dataWahrhouse.content[0].fees,this.idWharhouse._id);
        }else{
          this.fees={confirmation: -1,fulfillement_fees:-1,shipping: -1,refund: -1,change: -1,quality_control: -1,}
        }
        if(dataWahrhouseSetting.content.length>0){
           this.settings=await this.getElementInArray(dataWahrhouseSetting.content[0].settings,this.idWharhouse._id);
        }else{
          this.settings={ open_colis:false,confirmation:false,fulfillement:false,shipping:false,}
        }
        this.formData = editData.content;
        console.log('formdata role 3', this.formData)    
        if(!this.formData.settings) this.formData.settings=[];
        // this.formData.role=this.formData.role._id;
          this.roleformdata=this.formData.role;
           //this.selectContries=this.formData.countries;
         this.ConvertArrayToCountyName(this.formData.countries); 
    
       // this.formData.category = this.formData.categories[0];
        this.selectedRole=this.formData.role._id;
        //if(!this.formData.sous_warehouses) this.formData.sous_warehouses=[];
        if(['StockManager','Administrateur'].indexOf(this.formData.type) !== -1) {await this.getWarehousingParent(this.formData.countries[0]); }
        // this.currentZone=this.formData.zones; 
        // for (let j = 0; j <this.currentZone.length; j++) {
        //     for (let i = 0; i <this.allZones.length; i++) {
        //       if(this.zones[i]._id == this.currentZone[j]){
        //           this.zones[i].check=this.zones[i]._id;
        //         }
        //       }
        //     }     
        
      }
      
    },
    ConvertArrayToCountyName(data){
       data.forEach(function(part, index, theArray) {
            var count = part.length;
            if(count==2) theArray[index] = this.$f.getCountryNameByCode(this.options,part);
          }.bind(this));    
    },
    ConvertArrayToCountyCode(data){
       data.forEach(function(part, index, theArray) {
            var count = part.length;
            if(count>2) theArray[index] = this.$f.getCountryCodeByName(this.options,part);
          }.bind(this));    
    },
    async getCategories() {
      const res = await this.$server.search("categories");
      if (res.content.results) this.categories = res.content.results;
      else this.categories = [];
    },

    async getRoles() {
      const res = await this.$server.search("roles",{limit:10});
      if (res.content.results) this.roles = res.content.results;
      else this.roles = [];
    },
    async getZones() {

      const res = await this.$server.search("zones",{ limit: 1000,country:await this.warhouseSelected});
      if (res.content.results) {
        this.zones = res.content.results;
        
          for (let i = 0; i < this.zones.length; i++) {
              this.allZones.push(this.zones[i]._id);
          } 
        }
      else {this.zones = this.allZones=[]};
    },
  },
};
</script>


<style scoped>
.vs__dropdown-toggle{
  border:none
}
.object {
  width: 300px;
  height: 300px;
  margin-left: 80px;
  opacity: 0;
  background: white;
  border-radius: 5%;
  border-color: #d3d3d3;
  border-width: thin;
  transform: scaleY(0.8) skewX(10deg);
  transform-origin: 50% 0%;
  transition-duration: 0.25s;
}
.button:hover + .object {
  opacity: 1;
  transform: scaleY(1) skewX(0deg);
}
.box-1 {
    background: #f2f4f7;
    border: 2px solid #e8ebed;
    border-radius: .375rem;
}
.w-10\/12 {
    width: 83.333333%;
}
</style>


